import { createStore } from "vuex";

import axios from "axios";

axios.defaults.baseURL = import.meta.env.VITE_BASE_URL;

export default createStore({
  state() {
    return {
      active_bed: null,
      active_bed_idx: null,
      taskDefs: null,
      beds: null,
      keycloak: null,
    };
  },
  mutations: {
    async fetchBeds(state) {
      const response = await axios.get("/bed");
      let beds = response.data;
      state.beds = {};
      for (let bed of beds) {
        state.beds[bed.id] = bed;
        axios
          .get("/bed/" + bed.id + "/last_check")
          .then(
            (response) =>
              (state.beds[bed.id].checked_at = response.data
                ? new Date(response.data.checked_at)
                : null),
          )
          .catch((error) => {
            throw error;
          });
        if (bed.patient_id) {
          axios
            .get("/task/bed/" + bed.id + "/patient/" + bed["patient"].id)
            .then(
              (response) =>
                (state.beds[bed.id].tasks = Object.fromEntries(
                  response.data.map((task) => [task.id, task]),
                )),
            )
            .catch((error) => {
              state.beds.tasks = {};
              throw error;
            });
        }
      }
    },
    async setBedChecked(state) {
      let checked_at = new Date();
      let bed_id = state.active_bed.id;
      axios
        .post(`/bed/${state.active_bed.id}/check`, {
          checked_at: checked_at.toISOString(),
        })
        .then(() => {
          state.beds[bed_id].checked_at = checked_at;
          state.active_bed = null;
        })
        .catch((error) => {
          throw error;
        });
    },
    async createTask(state, task_definition_id) {
      let bed = state.active_bed;
      let bed_id = bed.id;
      axios
        .post(`/task`, {
          task: {
            bed_id: bed.id,
            patient_id: bed["patient"].id,
            task_definition_id: task_definition_id,
          },
        })
        .then((response) => {
          let tasks = state.beds[bed_id].tasks;
          for (let task of Object.values(tasks)) {
            if (task.task_definition_id === task_definition_id) {
              delete tasks[task.id];
            }
          }
          state.beds[bed_id].tasks[response.data.id] = response.data;
        })
        .catch((error) => {
          throw error;
        });
    },
    async completeTask(state, { bed_id, task_id }) {
      axios
        .post(`/task/${task_id}/complete`)
        .then((response) => {
          state.beds[bed_id].tasks[task_id] = response.data;
        })
        .catch((error) => {
          throw error;
        });
    },
    async setTaskNotCompletable(state, { bed_id, task_id }) {
      axios
        .put(`/task/${task_id}/not-completable`)
        .then((response) => {
          state.beds[bed_id].tasks[task_id] = response.data;
          console.log(response.data.not_completable);
          console.log(state.active_bed.tasks[task_id].not_completable);
        })
        .catch((error) => {
          throw error;
        });
    },
    async fetchTaskDefs(state) {
      const response = await axios.get("/task-definition");
      state.taskDefs = response.data;
    },
    async setTaskActive(state, { bed_id, task_id, active }) {
      active = active ? 1 : 0;
      axios
        .put(`/task/${task_id}/active?is_active=${active}`)
        .then((response) => {
          state.beds[bed_id].tasks[task_id] = response.data;
        })
        .catch((error) => {
          throw error;
        });
    },

    set_active_bed(state, bed) {
      state.active_bed = bed;
    },
    async deleteTask(state, task_id) {
      let bed_id = state.active_bed.id;
      let patient_id = state.active_bed.patient.id;
      axios
        .delete(`/task/${task_id}`)
        .then((response) => {
          axios
            .get("/task/bed/" + bed_id + "/patient/" + patient_id)
            .then((response) => {
              // delete state.beds[bed_id].tasks[task_id];
              state.beds[bed_id].tasks = Object.fromEntries(
                response.data.map((task) => [task.id, task]),
              );
            })
            .catch((error) => {
              state.beds.tasks = {};
              throw error;
            });
          // state.active_bed.tasks=state.beds[bed_id];
        })
        .catch((error) => {});
    },

    add_task_to_active_bed(state, task_name) {
      state.active_bed.tasks = state.active_bed.tasks.concat({
        name: task_name,
        created_utc: new Date(),
        completed_at: null,
      });
    },

    remove_task(state, task_name) {
      state.active_bed.tasks = state.active_bed.tasks.filter(
        (task) => task.name !== task_name,
      );
    },
  },
  getters: {
    is_active_bed: (state) => (bed) => {
      return state.active_bed && state.active_bed.id === bed.id;
    },
  },
});
