<script>
export default {
  name: "Loading",
  props: {
    message: {
      type: String,
      default: "Loading...",
    },
  },
};
</script>

<template>
  <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
</template>

<style scoped></style>
