function formatDate(date) {
  if (!date) {
    return "";
  }
  if (typeof date === "string") {
    date = new Date(date);
  }
  return date.toLocaleDateString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
}

export { formatDate };
