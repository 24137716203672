<script>
import store from "../js/store";
import { mapGetters, mapState } from "vuex";
import { formatDate } from "../js/date_formatters";

export default {
  name: "TableRowOverview",
  props: {
    bed: {
      type: Object,
      required: true,
    },
  },
  computed: {
    store() {
      return store;
    },
    ...mapGetters(["is_active_bed"]),
    ...mapState({
      active_bed: (state) => state.active_bed,
    }),
    number_of_tasks_done() {
      return Object.values(this.bed.tasks || {}).filter(
        (task) => task.completed_at,
      ).length;
    },
    number_of_tasks_not_done() {
      return Object.values(this.bed.tasks || {}).filter(
        (task) => !task.completed_at && !task.not_completable,
      ).length;
    },
    number_of_not_completable_tasks() {
      return Object.values(this.bed.tasks || {}).filter(
        (task) => task.not_completable && !task.completed_at,
      ).length;
    },
  },
  methods: { formatDate },
};
</script>

<template>
  <tr :class="{ 'table-active': is_active_bed(bed) }">
    <th scope="row">
      <span>{{ bed.name }}</span>
    </th>
    <td>
      <span
        v-if="number_of_tasks_done"
        class="badge rounded-pill bg-success m-1"
        >{{ number_of_tasks_done }}</span
      >
      <span
        v-if="number_of_tasks_not_done"
        class="badge rounded-pill bg-danger m-1"
        >{{ number_of_tasks_not_done }}</span
      >
      <span
        v-if="number_of_not_completable_tasks"
        class="badge rounded-pill bg-info m-1"
        >{{ number_of_not_completable_tasks }}</span
      >
      <span v-if="!bed.patient_id" class="badge rounded-pill bg-black m-1">
        leer
      </span>
      <span
        v-else-if="!number_of_tasks_done && !number_of_tasks_not_done"
        class="badge rounded-pill bg-secondary m-1"
        >Neu</span
      >
    </td>
    <td>
      <span
        v-for="task in bed.flags"
        :key="task"
        class="badge bg-primary m-1"
        >{{ task }}</span
      >
    </td>
    <td>
      {{
        bed.patient_id
          ? bed.patient.first_name + " " + bed.patient.last_name
          : "-"
      }}
    </td>
    <td>{{ bed.patient_id ? formatDate(bed.checked_at) : "" }}</td>
    <td>
      <button
        v-if="!is_active_bed(bed) && !!bed.patient_id"
        class="btn btn-dark btn-sm"
        @click="store.commit('set_active_bed', bed)"
      >
        <i class="bi bi-pencil"></i>
      </button>
    </td>
  </tr>
</template>
