<script setup>
import EditBed from "../components/EditBed.vue";
import TableRowOverview from "../components/TableRowOverview.vue";
import Loading from "@/components/Loading.vue";
</script>

<template>
  <div class="container py-4 px-3 mx-auto" v-if="beds">
    <h1>Betten</h1>
    <div>
      <table class="table">
        <thead class="thead">
          <tr>
            <th scope="col">Bett Nummer</th>
            <th scope="col">Aufgaben</th>
            <th scope="col"></th>
            <th scope="col">Patient</th>
            <th scope="col">Letzte Prüfung</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <TableRowOverview v-for="bed in beds" :key="bed.id" :bed="bed" />
        </tbody>
      </table>
      <EditBed />
    </div>
  </div>
  <div v-else>
    <Loading />
  </div>
</template>

<script>
import store from "@/js/store";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  computed: {
    store() {
      return store;
    },
    ...mapState({
      beds: (state) => state.beds,
    }),
  },
  methods: {},
  async mounted() {
    this.store.commit("fetchBeds");
    this.store.commit("fetchTaskDefs");
  },
};
</script>
