import { createApp } from "vue";
import App from "./App.vue";
import Keycloak from "keycloak-js"; // Import our custom CSS
import "../scss/styles.scss";
import store from "./store";
import axios from "axios";
import * as Sentry from "@sentry/vue";

let initOptions = {
  url: import.meta.env.VITE_KEYCLOAK_URL,
  realm: import.meta.env.VITE_KEYCLOAK_REALM,
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
};

let keycloak = new Keycloak(initOptions);
keycloak
  .init({ onLoad: initOptions.onLoad })
  .then((auth) => {
    if (!auth) {
      return keycloak.login();
    }

    axios.interceptors.request.use(async (request) => {
      await keycloak.updateToken(30);
      if (keycloak.isTokenExpired()) {
        await keycloak.logout();
        await keycloak.login();
      }
      request.headers.set("Authorization", `Bearer ${keycloak.token}`);
      return request;
    });

    store.state.keycloak = keycloak;

    // axios.defaults.headers.Authorization = `Bearer ${store.state.keycloak.token}`;

    axios.interceptors.request.use(
      async (config) => {
        if (store.state.keycloak.token) {
          if (store.state.keycloak.isTokenExpired()) {
            await store.state.keycloak.updateToken(30);
          }
          config.headers.Authorization = `Bearer ${store.state.keycloak.token}`;
        }
        return config;
      },
      function (error) {
        console.error("axios interceptor error", error);
        return Promise.reject(error);
      },
    );

    const app = createApp(App);

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.vueIntegration({ app }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["*"],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      environment: import.meta.env.VITE_APP_ENVIRONMENT,
      release: import.meta.env.VITE_APP_VERSION,
    });
    app.use(store);
    app.mount("#app");
  })
  .catch((reason) => {
    console.error("Authenticated Failed", reason);
    throw reason;
  });

