<script>
import store from "../js/store";
import { mapState } from "vuex";
import { formatDate } from "../js/date_formatters";
import Loading from "@/components/Loading.vue";

export default {
  name: "EditBed",
  components: { Loading },
  data() {
    return {};
  },
  computed: {
    store() {
      return store;
    },
    ...mapState({
      taskDefs: (state) => state.taskDefs,
      new_tasks_for_bed: (state) => {
        let current_task_def_ids = Object.values(state.active_bed.tasks)
          .filter((task) => task.active)
          .map((task) => task.task_definition_id);
        return state.taskDefs.filter(
          (taskDef) => !current_task_def_ids.includes(taskDef.id),
        );
      },
      active_bed: (state) => state.active_bed,
    }),
  },
  methods: { formatDate },
};
</script>

<template>
  <div v-if="active_bed" class="card">
    <div class="card-body">
      <h2>{{ active_bed.name }}</h2>
      <table v-if="Object.values(active_bed.tasks).length" class="table">
        <thead>
          <tr>
            <th scope="col">Task</th>
            <th scope="col">Status</th>
            <th scope="col">Erstellt</th>
            <th scope="col">Erledigt</th>
            <th scope="col">Aktionen</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="task in Object.values(active_bed.tasks)
              .filter((task) => task.active)
              .sort((a, b) =>
                a.task_definition.title.localeCompare(b.task_definition.title),
              )"
            :key="task.name"
          >
            <td>
              {{ task.task_definition.title }}
            </td>
            <td>
              <span
                :class="{
                  'bg-success': task.completed_at,
                  'bg-info': task.not_completable,
                  'bg-danger': !task.completed_at && !task.not_completable,
                }"
                class="badge rounded-pill m-1"
                >{{
                  task.completed_at
                    ? "done"
                    : task.not_completable
                      ? "not-completable"
                      : "open"
                }}
              </span>
            </td>
            <td>{{ formatDate(task.created_at) }}</td>
            <td>
              <span
                :class="{
                  'text-info': !task.completed_at,
                }"
                >{{
                  formatDate(task.completed_at) ||
                  formatDate(task.not_completable)
                }}
              </span>
            </td>
            <td>
              <div v-if="!task.completed_at && !task.not_completable">
                <button
                  class="btn btn-success btn-sm m-1"
                  @click="
                    store.commit('completeTask', {
                      bed_id: active_bed.id,
                      task_id: task.id,
                    })
                  "
                >
                  <i class="bi bi-check"></i>
                </button>
                <button
                  class="btn btn-info btn-sm m-1"
                  @click="
                    store.commit('setTaskNotCompletable', {
                      bed_id: active_bed.id,
                      task_id: task.id,
                    })
                  "
                >
                  <i class="bi bi-infinity"></i>
                </button>
                <!--                <button-->
                <!--                  class="btn btn-danger btn-sm m-1"-->
                <!--                  @click="store.commit('deleteTask', task.id)"-->
                <!--                >-->
                <!--                  <i class="bi bi-trash"></i>-->
                <!--                </button>-->
              </div>
              <div v-else>
                <button
                  class="btn btn-outline-light btn-sm m-1"
                  @click="store.commit('createTask', task.task_definition_id)"
                >
                  <i class="bi bi-repeat"></i>
                </button>
                <button
                  class="btn btn-outline-danger btn-sm m-1"
                  @click="
                    store.commit('setTaskActive', {
                      task_id: task.id,
                      bed_id: active_bed.id,
                      active: false,
                    })
                  "
                >
                  <i class="bi bi-arrow-down-square"></i>
                </button>
                <!--                <button-->
                <!--                  class="btn btn-danger btn-sm m-1"-->
                <!--                  @click="store.commit('deleteTask', task.id)"-->
                <!--                >-->
                <!--                  <i class="bi bi-trash"></i>-->
                <!--                </button>-->
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!--      <div v-for="task_name in new_tasks_for_bed" :key="task_name" class="row">-->
      <div
        v-for="taskDef in new_tasks_for_bed"
        :key="taskDef.title"
        class="row"
        v-if="taskDefs"
      >
        <div class="row">
          <div class="col-sm-1">
            <button
              class="btn btn-success btn-sm m-1"
              @click="store.commit('createTask', taskDef.id)"
            >
              <!--              <i class="bi bi-plus-circle"></i>-->
              <i class="bi bi-arrow-up-square"></i>
            </button>
          </div>
          <div class="col m-2">
            {{ taskDef.title }}
          </div>
        </div>
      </div>
      <Loading v-else />

      <div class="col align-right mt-3">
        <button
          class="btn btn-sm btn-warning float-end m-1 mt-2"
          @click="
            () => {
              store.commit('setBedChecked', {
                bed: active_bed,
                checked_at: '1900-01-01',
              });
            }
          "
        >
          <i class="bi bi-check"></i>
          checked
        </button>
      </div>
    </div>
  </div>
</template>
